.header {
    padding-bottom: 30px;

    @include susy-breakpoint($lg) {
        padding-bottom: 55px;
    }

    &__contacts {
        @include susy-breakpoint($lg) {
            padding-left: 0;
            padding-right: 0;
        }

        .contacts__row {
            @include susy-breakpoint($xl) {
                padding-left: 75px;
            }

            .icon {
                @include susy-breakpoint($xl) {
                    left: 45px;
                }
            }
        }
    }

    &__phones {
        .contacts {
            padding-top: 0;

            @include susy-breakpoint($sm) {
                padding-top: 20px;
            }

            @include susy-breakpoint($lg) {
                padding-top: 0;
            }

            @include susy-breakpoint($xl) {
                padding-top: 20px;
            }
        }

        .link {
            display: inline-block;
            font-size: 11px;
            
            @include susy-breakpoint($sm) {
                width: 100%;
                text-align: right;
                font-size: $font-size;
            }

            &:first-child {
                &::after {
                    content: ', ';
                    display: inline;
            
                    @include susy-breakpoint($sm) {
                        display: none;
                    }
                }
            }
        }

        .icon {
            left: 0;

            @include susy-breakpoint($md) {
                left: 60px;
            }

            @include susy-breakpoint($xl) {
                left: 0;
            }
        }

        .socialls {
            display: none;

            @include susy-breakpoint($lg) {
                display: block;
            }
        }
    }
}