.gototop {
    background: none; 
    border: solid 2px $color-brand;
    display: inline-block;
    padding: 3px 6px;
    vertical-align: top;

    .icon {
        vertical-align: middle;
        cursor: pointer;
        margin-right: 0;
        fill: none;
    }

    &:hover {
        background: $color-brand;
    }
}