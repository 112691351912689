#home {
    .main-menu--desktop {
        margin-bottom: 0;
    }
}
.main-menu {
    &--desktop {
        position: relative;
        height: 0;
        margin-bottom: 30px;

        .main-menu__button {
            display: none;
        }

        .menu {
            &__trigger {
                display: none;
            }

            &__list {
                margin: 0;
                padding: 0;
                list-style: none;
            }
            
            &__item {
                display: inline-block;
                position: relative;
    
                .menu__list {
                    display: none;
                    position: absolute;
                }
            }

            &__link {
                text-decoration: none;
                font-size: 1em;
                font-weight: 500;
                font-style: normal;
                line-height: 1.5;
            }

            & > .menu__list {
                position: absolute;
                top: -35px;
                width: calc(100% - 30px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                z-index: 10;
            }

            & > .menu__list > .menu__item {
                flex-grow: 1;

                &.menu__item--active > .menu__link {
                    color: $color-yellow-1;
                }

                &:last-child {
                    & > .menu__link {
                        border-right: 0;
                    }
                }

                &:hover {
                    .menu__list {
                        display: block;
                        padding-top: 13px;
                        min-width: 246px;

                        @include susy-breakpoint ($xl) {
                             min-width: 273px;
                        }

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background: $color-gray-1;
                            transform: rotate(45deg);
                            top: 8px;
                            left: 50px;
                        }

                        .menu__item {
                            display: block;
                            
                            &:last-child {
                                .menu__link {
                                    border-bottom: 0;
                                }
                            }

                            &--active {
                                .menu__link {
                                    background: $color-yellow-1;
                                    color: $font-color-base;
                                    border-bottom: 1px solid $color-yellow-1;
                                }
                            }
                        }
                        
                        .menu__link {
                            display: block;
                            background: $color-gray-1;
                            padding: 15px 27px;
                            border-bottom: 1px solid $color-gray-3;

                            &:hover {
                                background: $color-yellow-1;
                                color: $font-color-base;
                                border-bottom: 1px solid $color-yellow-1;
                            }
                        }
                    }

                    &:last-child {
                        .menu__list {
                            right: 0;

                            &::before {
                                left: auto;
                                right: 50px;
                            }
                        }
                    }

                    & > .menu__link {
                        background: $color-gray-1;
                        color: $font-color-base;
                    }
                }
            }

            & > .menu__list > .menu__item > .menu__link {
                color: white;
                display: block;
                height: 100%;
                padding: 22px 10px;
                border-right: 1px solid $color-gray-5;

                @include susy-breakpoint ($xl) {
                    padding: 22px 25px;
                }

                &:hover {
                    background: $color-gray-1;
                    color: $font-color-base;
                }
            }
        }
    }

    &--phone {

        .main-menu__button {
            padding: 22px 15px;
            position: relative;

            &.u-bg-brand {
                .main-menu__text {
                    color: $font-color-base;
                }
    
                .main-menu__sandwich {
                    background: $font-color-base;

                    &::after, &::before {
                        background: $font-color-base;
                    }
                }
            }
        }

        .handle-menu {
            display: inline-block;
        }

        .main-menu__sandwich {
            position: relative;
            top: -9px;
            display: inline-block;
            width: 18px;
            height: 2px;
            background: white;

            &::after, &::before {
                content: '';
                display: inline-block;
                position: absolute;
                left: 0;
                width: 18px;
                height: 2px;
                background: white;
            }

            &::before {
                bottom: -5px;
            }

            &::after {
                bottom: -10px;
            }
        }

        .main-menu__text {
            color: $color-yellow-1;
            font-size: 0.875em;
            text-transform: uppercase;
            font-weight: 500;
            padding-left: 8px;
        }

        .main-menu__socialls {
            display: none;

            &--active {
                display: inline-block;
                float: right;

                .socialls__item {
                    padding: 0 0 0 12px;
                }

                .icon {
                    fill: $color-gray-6;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .main-menu__close {
            display: none;

            &--active {
                display: inline-block;
                width: 22px;
                height: 22px;
                position: absolute;
                top: 50%;
                right: 15px;

                &::before,
                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    width: 15px;
                    height: 2px;
                    background: $font-color-base;
                    top: 0;
                }
                
                &::before {
                    transform: rotate(45deg);
                }
                
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }

        .menu {
            display: none;
            padding: 0;

            &--active {
                display: block;
                
                .menu__trigger {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 55px;
                    height: 100%;
                    
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 28px;
                        left: 50%;
                        margin-left: -4px;
                        width: 8px;
                        height: 8px;
                        border: 2px solid white;
                        border-left-color: transparent;
                        border-bottom-color: transparent;
                        transform: rotate(45deg);
                        transition: transform .2s;
                    }

                    &--active {
                        &::before {
                            transform: rotate(135deg);
                            transition: transform .2s;
                        }
                    }
                }
            }
            
            &__list {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            &__item {
                border-bottom: 1px solid $color-dark-4;
            }
            
            &__link {
                text-decoration: none;
                font-size: 0.938em;
                color: white;
                display: block;
                height: 100%;
                padding: 19px 15px;
                margin-right: 55px;
            }

            & > .menu__list {
                & > .menu__item {
                    position: relative;

                    &--active {
                        background: $color-dark-3;
                    }

                    .menu__list {
                        display: none;

                        &--active {
                            display: block;
                        }

                        .menu__item {
                            &--active {
                                background: $color-dark-3;
                            }

                            &:first-child {
                                border-top: 1px solid $color-dark-4;
                            }
                        }

                        .menu__link {
                            padding-left: 42px;
                        }
                    }
                }
            }
        }
    }
}