.files {

    &__title {
        padding-bottom: 5px;

        @include susy-breakpoint($md) {
            display: none;
        }
    }

    &__name {
        text-align: center;

        @include susy-breakpoint($md) {
            text-align: left;
        }
    }

    &__size {
        text-align: center;

        @include susy-breakpoint($md) {
            text-align: left;
            width: 120px;
        }
    }

    &__get {
        text-align: center;
        @include susy-breakpoint($md) {
            width: 80px;
        }
    }
}