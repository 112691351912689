.about {
    &__content {
        margin-top: 63vw;

        @include susy-breakpoint($md) {
            margin-top: 66vw;
        }

        @include susy-breakpoint($xl) {
            margin-top: 0;
            padding-right: 60px;
        }
    }
}