.grid {
    display: flex;
    flex-wrap: wrap;

    &__3-1 {
        flex: 0 0 100%;
        max-width: 100%;

        @include susy-breakpoint($md) {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }
}