.sitename {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 117px;

    &__text {
        font-size: 0.75em;
        line-height: 1.76;
        color: $color-dark-1;

        @include susy-breakpoint($xs) {
             font-size: 0.937em;
        }
    }

    &__big-text {
        font-size: 1em;
        font-weight: 900;
        line-height: 1.36;
        letter-spacing: 0.6px;
        text-transform: uppercase;

        @include susy-breakpoint($xs) {
             font-size: 1.2em;
        }
    }
}