.icon {
    &--logo {
        width: 130px;
        height: 90px;

        @include susy-breakpoint($xl) {
            width: 180px;
            height: 117px;
        }
    }

    &--map {
        width: 11px;
        height: 13px;
    }

    &--clock {
        width: 13px;
        height: 13px;
    }

    &--phone {
        width: 14px;
        height: 14px;
    }

    &--socialls {
        width: 21px;
        height: 21px;
    }

    &--men {
        width: 42px;
        height: 42px;   
    }

    &--calendar {
        width: 42px;
        height: 42px;
    }

    &--target {
        width: 42px;
        height: 42px;
    }

    &--eye {
        width: 13px;
        height: 13px;
    }

    &--question,
    &--photo {
        width: 16px;
        height: 16px;
    }

    &--home {
        width: 20px;
        height: 20px;
    }

    &--infinity {
        width: 38px;
        height: 38px;
    }

    &--save {
        width: 21px;
        height: 21px;
    }

    &--arrow {
        width: 13px;
        height: 13px;
    }

    &--book,
    &--target2,
    &--prize {
        width: 26px;
        height: 26px;
    }

    &--cancel {
        width: 23px;
        height: 23px;
    }

    &--up {
        width: 24px;
        height: 24px;
    }
}