.eye {
    padding-bottom: 20px;

    &__link {
        color: $color-gray-4;
        text-decoration: none;
        font-size: 0.875em;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .icon {
            fill: $color-gray-4;
            margin-right: 5px;
        }
    }
}