.form-img {
  overflow: hidden;
}

.form {
  padding: 75px 30px 0;

  @include susy-breakpoint($md) {
    padding: 75px 65px 30px;
  }

  &__header {
    padding-bottom: 0px;

    @include susy-breakpoint($md) {
      padding-bottom: 25px;
    }
  }

  &__footer {
    @include susy-breakpoint($md) {
      padding-top: 15px;
      padding-bottom: 25px;
    }
  }

  &__chechbox-text {
    font-size: 0.8em;

    .link {
      font-size: 1em;
      line-height: 1.1;
    }
  }

  &__label {
    display: block;
    position: relative;
    margin-bottom: 20px;

    @include susy-breakpoint($sm) {
      margin-bottom: 30px;
    }

    &--checkbox {
      padding-left: 30px;
    }
  }

  .false-checkbox {
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 13px;
    height: 13px;
    border: 2px solid $color-gray-13;

    &--error {
      border-color: $color-red-1;
    }
  }

  .placeholder {
    transition: all 0.25s ease-in-out;
    position: absolute;
    top: 10px;
    left: 0;
    text-transform: uppercase;
    // font-size: 20px;
  }

  &__field--control:focus + .placeholder,
  .placeholder.filled {
    left: 0;
    top: -20px;
    font-size: 10px;
    text-transform: none;
  }

  &__field {
    &--checkbox {
      display: none;

      &:checked + .false-checkbox::before {
        content: '';
        display: block;
        position: absolute;
        top: -2px;
        left: -1px;
        width: 12px;
        height: 8px;
        border: 2px solid $color-green-2;
        border-right-color: transparent;
        border-top-color: transparent;
        transform: rotate(-45deg);
      }
    }

    &--text,
    &--textarea {
      width: 100%;
      border: none;
      background: none;
      outline: none;
      border-bottom: 2px solid $color-gray-13;
      padding: 10px 0;
    }

    &--textarea {
      border: 2px solid $color-gray-13;
    }
  }

  .error {
    position: absolute;
    font-size: 0.625em;
    color: $color-red-1;
    width: 100%;
    bottom: -20px;
    left: 0;
  }

  .button {
    font-size: 1em;
    color: $color-dark-1;
    padding: 10px 40px;
    background-color: $color-yellow-2;
    border-color: $color-yellow-2;

    &:hover {
      background-color: $color-yellow-3;
      border-color: $color-yellow-3;
    }

    &[disabled] {
      background-color: $color-gray-13;
      border-color: $color-gray-13;

      span {
        color: $color-dark-1;
      }
    }
  }
}
