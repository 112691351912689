.logo {
    width: 180px;
    margin: 0 auto;

    @include susy-breakpoint($sm) {
        width: 100%;
        margin: 0;
        text-align: left;
    }
    @include susy-breakpoint($md) {
        text-align: right;
    }
}