.heading {
    margin: 0;
    padding: 0;
    font-weight: 500;
	font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: 0;
    text-align: left;
    padding-bottom: 30px;

    &--h1 {
        font-size: 2.8em;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 2em;
            text-align: left;
        }
    }

    &--h2 {
        font-size: .937em;
        font-weight: bold;
        font-style: normal;
        line-height: 1.2;
        text-transform: uppercase;
        letter-spacing: normal;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 1.75em;
            font-weight: 500;
            text-align: left;
        }
    }

    &--h3 {
        font-size: 1.3em;
        font-weight: 300;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 1.875em;
            text-align: left;
        }
    }

    &--h4 {
        font-size: 1.1em;
        font-weight: bold;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 1.5em;
            text-align: left;
        }
    }
}