.tags {
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        display: inline-block;
        padding: 0 15px 20px;
    }

    &__link {
        display: block;
        height: 100%;
        border-radius: 24px;
        border: solid 1px $color-dark-2;
        font-size: 1em;
        font-weight: normal;
        padding: 10px 25px;
        letter-spacing: 0.3px;
        color: $color-dark-2;
        text-decoration: none;
        
        &:hover {
            background-color: $color-dark-2;
            color: white;
        }
    }
}