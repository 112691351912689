.u-align-r {
	text-align: right;
}

.u-align-c {
	text-align: center;
}

.u-align-l {
	text-align: left;
}

.u-align-j {
	text-align: justify;
}