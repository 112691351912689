#contacts {
    .header {
        .contacts {
            padding-top: 20px;

            &__row {
                font-size: 0.7em;

                @include susy-breakpoint($sm) {
                    font-size: 1em;
                }
            }
        }

        .phones {
            padding-top: 0;

            @include susy-breakpoint($sm) {
                padding-top: 20px;
            }

            .contacts {
                padding-top: 0;
            }
        }
    }
    .contacts {
        padding-top: 0;

        .contactmap {
            width: 100%;
            min-height: 520px;
        }

        &__row {
            font-weight: normal;
            margin: 0;
            width: 100%;
            font-size: 1em;
        }

        &__right-content {
            .heading {
                font-size: 0.9em;
                text-transform: uppercase;
                font-weight: 500;
                font-style: normal;
                line-height: 1.33;
                padding: 0;
                text-align: center;
                padding: 15px;

                @include susy-breakpoint($sm) {
                    font-size: 1.125em;
                }

                @include susy-breakpoint($md) {
                    display: none;
                }
            }

            p {
                padding-left: 25px;

                @include susy-breakpoint($md) {
                    padding-left: 0;
                }
            }
        }
    }
}