.breadcrumbs {

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        display: inline-block;
        line-height: 20px;

        &--home {
            .breadcrumbs__link {
                font-size: 0;
                line-height: 0;
                padding-left: 0;
            }
        }
    }

    &__link,
    &__current {
        text-decoration: none;
        padding: 0 17px;
        font-size: 0.75em;
        line-height: 20px;
        vertical-align: middle;
    }

    &__link {
        position: relative;
        display: inline-block;
        color: $color-gray-7;

        &:hover {
            text-decoration: underline;
        }

        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            right: -4px;
            top: 33%;
            width: 8px;
            height: 8px;
            border: 1px solid $color-gray-7;
            border-left-color: transparent;
            border-bottom-color: transparent;
            transform: rotate(45deg);
        }

        .icon {
            fill: $color-gray-7;
        }
    }

    &__current {
        color: $color-dark-1;
    }
}