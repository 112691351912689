.footer {
    &__logo {
        text-align: center;
        width: 180px;
        margin: 0 auto;
        padding-bottom: 30px;

        @include susy-breakpoint ($lg) {
            text-align: left;
            width: 100%;
            margin: 0;
            padding-bottom: 0;
        }
    }

    .logo__img {
        display: inline-block;
        max-width: 180px;
    }

    &__contacts {
        padding-top: 0;
        
        .contacts {
            &__row {
                
                @include susy-breakpoint($xs) {
                    width: 375px;
                    margin: 0 auto;
                    padding-left: 50px;
                }

                @include susy-breakpoint($lg) {
                    width: 100%;
                    margin: 0;
                    font-size: 1em;
                    padding-left: 30px;
                }
            }
            
            &__text,
            &__text .link--phone {
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: $color-gray-1;
            }
        }

        .icon {
            fill: white;

            @include susy-breakpoint($xs) {
                left: 20px;
            }

            @include susy-breakpoint($lg) {
                left: 0;
            }
        }
    }

    &__ask {
        text-align: center;

        @include susy-breakpoint ($lg) {
            text-align: left;
        }

        
        .button {
            font-size: o.75em;
            font-weight: 500;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: 0.5px;
            padding-top: 5px;
            padding-bottom: 5px;
            
            .icon {
                fill: white;
                margin-right: 10px;
            }
            
            &:hover {
                color: black;

                .icon {
                    fill: black;
                }
            }
        }
    }

    .socialls {
        &__list {
            display: none;
            
            @include susy-breakpoint ($lg) {
                display: block;
                text-align: left;
                padding-top: 8px;
            }
        }

        &__item {
            padding: 0 10px;
        }

        &__link {
            &:hover {
                opacity: .5;
            }
        }

        .icon {
            width: 27px;
            height: 27px;
            fill: white;
        }
    }

    .menu {
        display: none;

        @include susy-breakpoint ($lg) {
            display: block;
        }

        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            @include clearfix;
        }

        &__item {
            display: block;
            @include span(1 of 2 no-gutter);
            margin-bottom: 2px;

            &--active {
                .menu__link {
                    color: $color-yellow-2;
                }
            }
        }

        &__link {
            display: block;
            font-size: 0.875em;
            font-weight: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            text-decoration: none;

            &:hover {
                color: $color-yellow-2;
            }
        }
    }

    &__copy {
        order: 5;
        text-align: center;
        
        @include susy-breakpoint ($lg) {
            order: 0;
            text-align: left;
        }
    }
}
