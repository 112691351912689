$t: 0;
 @while $t < 155 {
 	.u-mt-#{$t} {
 		margin-top: #{$t}px;
 	}
 	$t: $t + 5;
 }

 $b: 0;
 @while $b < 155 {
 	.u-mb-#{$b} {
 		margin-bottom: #{$b}px;
 	}
 	$b: $b + 5;
 }

 $l: 0;
 @while $l < 155 {
 	.u-ml-#{$l} {
 		margin-left: #{$l}px;
 	}
 	$l: $l + 5;
 }

 $r: 0;
 @while $r < 155 {
 	.u-mr-#{$r} {
 		margin-right: #{$r}px;
 	}
 	$r: $r + 5;
 }

 .u-m-0 {
	 margin: 0;
 }