.price {
    color: $color-dark-1;
    @include clearfix;

    &__header {
        font-size: 1.25em;
        text-align: center;
        background: $color-yellow-1;
        padding: 20px 5px;
        font-weight: 500;
    }
    
    &__subheader {
        background: $color-gray-3;
        font-size: 0.875em;
        text-align: center;
        font-weight: 500;
    }

    &__part {
        @include span(1 of 2 no-gutter);

        @include susy-breakpoint($sm) {
            float: none;
            width: 100%;
        }

        &--1 {
            .price__item {
                border-left: 1px solid $color-gray-3;

                @include susy-breakpoint($sm) {
                    border-left: 0;        
                }
            }

            .price__subheader {
                border-right: 1px solid white;

                @include susy-breakpoint($sm) {
                    border-right: 0;        
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        min-height: 65px;
        
        @include susy-breakpoint($sm) {
            flex-direction: row;            
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 0 span(full);
        max-width: span(full);
        min-height: 65px;
        border-right: 1px solid $color-gray-3;
        border-bottom: 1px solid $color-gray-3;
        
        @include susy-breakpoint($sm) {
            flex: 0 0 span(1 of 7);
            max-width: span(1 of 7);          
        }

        &:first-child {
            
            @include susy-breakpoint($sm) {
                border-left: 1px solid $color-gray-3;                         
            }
        }

        &--empty {
            background: url('../img/bg-zibre.png');
        }

        .icon {
            width: 20px;
            height: 20px;

            @include susy-breakpoint($sm) {
                width: 30px;
                height: 30px;
            }

            @include susy-breakpoint($lg) {
                width: 38px;
                height: 38px;
            }
        }
    }

    &__content {
        font-style: normal;
        line-height: 1.25;
        
        &--big {
            font-weight: bold;
            color: black;
            
            @include susy-breakpoint($md) {
                font-size: 1.25em;
            }
        }

        &--small {
            font-size: 0.94em;
            font-weight: 500;
            color: $color-gray-9;
        }
    }
}