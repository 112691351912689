.pagination {
    text-align: center;

    &__list {
        padding: 0 0 30px;
        margin: 0;
        list-style: none;
    }

    &__item {
        display: inline-block;
        margin-right: 15px;
        padding-bottom: 0 !important;
        padding-left: 0 !important;

        a {
            text-decoration: none;
            padding: 3px 10px;

            &:hover {
                background: $color-yellow-1;
                color: white;
            }
        }

        &--prev {
            transform: rotate(180deg);
        }

        &--next,
        &--prev {
            a {
                &:hover {
                    background: none;

                    .icon {
                        fill: $color-yellow-1;
                    }
                }
            }
        }

        &--current {
            a {
                background: $color-yellow-1;
                color: white;
            }
        }
    }
}