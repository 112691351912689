.contacts {
    padding-top: 20px;

    @include susy-breakpoint($lg) {
        padding-top: 0px;
    }
    @include susy-breakpoint($xl) {
        padding-top: 20px;
    }
    
    &__row {
        padding-left: 25px;
        padding-bottom: 5px;
        position: relative;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        font-size: 0.7em;
        line-height: 1.5;

        @include susy-breakpoint(285px) {
            width: 285px;
            margin: 0 auto;
        }

        @include susy-breakpoint($xs) {
            width: 345px;
        }

        @include susy-breakpoint($sm) {
            width: 100%;
            margin: 0;
            font-size: 1em;
            padding-left: 20px;
        }

        @include susy-breakpoint($lg) {
            padding-bottom: 15px;
        }

        @include susy-breakpoint($xl) {
            padding-left: 30px;
        }
    
        .icon {
            position: absolute;
            left: 0;
            top: 1px;

            @include susy-breakpoint($sm) {
                top: 5px;
            }
        }
    }
}