html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img {
	max-width: 100%;
	width: 100%;
	height: auto;
}

a {
	color: inherit;
	outline: none;

	@include susy-breakpoint($sm) {
		font-size: $font-size;
	}

	&:hover {
		text-decoration: none;	
	}
}

p {
	margin: 0;
	padding: 0;
	padding-bottom: 30px;
	line-height: 1.6;

	@include susy-breakpoint($sm) {
		font-size: $font-size;
	}
}

//developers responsive markers
.dev {
	position: relative;

	&::before {
		content: "<	460px";
		color: white;
		text-shadow: 0 0 5px black;
		position: fixed;
		top: 2%;
		right: 5%;
		font-size: 30px;
		font-weight: 700;
		z-index: 1000;
	}
	@include susy-breakpoint($xs) {
		&:before {
			content: "xs	460px";
		}
	}
	@include susy-breakpoint($sm) {
		&:before {
			content: "sm	576px";
		}
	}
	@include susy-breakpoint($md) {
		&:before {
			content: "md	768px";
		}
	}
	@include susy-breakpoint($lg) {
		&:before {
			content: "lg	960px";
		}
	}
	@include susy-breakpoint($xl) {
		&:before {
			content: "xl	1200px";
		}
	}
}