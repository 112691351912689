.user-content {
    h1, h2, h3, h4 {
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    h1 {
        font-size: 1.1em;
        font-weight: 500;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 2em;
            text-align: left;
        }
    }

    h2 {
        font-size: .937em;
        font-weight: bold;
        font-style: normal;
        line-height: 1.6;
        text-transform: uppercase;
        letter-spacing: 0;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 1.75em;
            font-weight: 500;
            text-align: left;
        }
    }

    h3 {
        font-size: 1.3em;
        font-weight: 300;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 1.875em;
            text-align: left;
        }
    }

    h4 {
        font-size: 1.1em;
        font-weight: bold;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: center;
        
        @include susy-breakpoint($sm) {
            font-size: 1.5em;
            text-align: left;
        }
    }

    ul, ol {
        margin: 0 0 30px;
        padding: 0 0 0 17px;

        li {
            padding-bottom: 10px;
            padding-left: 10px;
            font-size: 14px;

            &:last-child {
                padding-bottom: 0;
            }

            @include susy-breakpoint($sm) {
                font-size: $font-size;
            }
        }
    }

    a {
        color: $color-blue-1;
    }

    blockquote {
        margin: 0;
        color: $color-gray-8;
    }

    table {
        margin-bottom: 30px;
    }
}