.section {
	@include clearfix;

	&--carousel {
		position: relative;
		display: none;

		@include susy-breakpoint($md) {
			display: block;
		}
	}

	&--header {
		padding-top: 70px;

		@include susy-breakpoint($lg) {
			padding-top: 0;
		}
	}

	&--tags {
		.heading {
			font-size: 1.125em;
		}
	}
}