.carousel {
    &__bg {
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    &__container {
        height: 538px;
    }

    &__row {
        height: 100%;
    }

    &__content {
        align-self: center;

        p {
            font-size: 1.125em;
        }
    }

    .button {
        padding: 3px 18px;
        background-color: $color-yellow-2;
        border-color: $color-yellow-2;

        &:hover {
            background-color: $color-yellow-3;
            border-color: $color-yellow-3;
        }
    }

    .slick-dots {
        bottom: 40px;

        button {
            &::before {
                display: none;
            }
            &::after {
                content: "";
                display: block;
                position: absolute;
                background: white;
                top: 50%;
                left: 50%;
                margin-top: -4px;
                margin-left: -4px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
        }

        .slick-active {
            button {
                &::after {
                    display: none;
                }

                &::before {
                    content: '';
                    display: block;
                    opacity: 1;
                    border: 2px solid $color-brand;
                    border-radius: 50%;
                }
            }
        }

    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}

.carousel-controls {
    position: absolute;
    padding-right: 15px;
    padding-left: 15px;
    bottom: 60px;
    
    @include susy-breakpoint (576px){
        width: 540px;
        max-width: 100%;
        left: 50%;
        margin-left: -270px;
    }
    @include susy-breakpoint (768px){
        width: 720px;
        max-width: 100%;
        left: 50%;
        margin-left: -360px;
    }
    @include susy-breakpoint (992px){
        width: 960px;
        max-width: 100%;
        left: 50%;
        margin-left: -480px;
    }
    @include susy-breakpoint (1200px){
        width: 1140px;
        max-width: 100%;
        left: 50%;
        margin-left: -570px;
    }

    .slick-arrow {
        width: 75px;
        height: 75px;
        background-color: $color-light-a3;
        transition: background 0.3s;
        z-index: 10;

        &::before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border: 3px solid white;
            transform: rotate(45deg);
            position: absolute;
            top: 38%;
            opacity: 1;
        }

        &:hover {
            background: $color-light-a7;
            transition: background 0.3s;
        }

        &.slick-next {
            right: 15px;

            @include susy-breakpoint (1300px){
                right: -75px;
            }

            &::before {
                left: 34%;
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }

        &.slick-prev {
            left: 15px;

            @include susy-breakpoint (1300px){
                left: -75px;
            }

            &::before {
                left: 47%;
                border-top-color: transparent;
                border-right-color: transparent;
            }
        }
    }
}