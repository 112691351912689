.u-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    overflow-y: auto;
    
    &--lg-stop {
        @include susy-breakpoint ($lg) {
            position: static;
            background: none;
            overflow-y: inherit;
        }
    }
}