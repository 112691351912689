.u-bg-brand {
    background-color: $color-yellow-1;
}

.u-bg-dark-1 {
    background: $color-dark-1;
}

.u-bg-gray-1 {
    background: $color-gray-1;
}

.u-bg-light {
    background: white;
}

// ---------------------------

.u-bg-half-1 {    
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center 0;

    @include susy-breakpoint($xl){
        background-color: white;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 100% 0;
    }

    .user-content {
        @include susy-breakpoint($xl){
            background: white;
        }
    }
}