.socialls {
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;
    }

    &__item {
        display: inline-block;
        padding: 0 0 0 4px;
    }

    &__link {
        &:hover {
            .icon {
                opacity: 0.5;
            }
        }
    }
}