$susy: (
	flow: ltr,
	math: fluid,
	output: float,
	gutter-position: inside,
	container: auto,
	container-position: center,
	columns: 12,
	gutters: 0.441,
	column-width: false,
	global-box-sizing: content-box,
	last-flow: to
);

// Breakpoints
$xs: 460px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// Colors
$color-dark-1: #1f1f1f;
$color-dark-2: #333;
$color-dark-3: #111;
$color-dark-4: #363636;

$color-red-1: #e20e0e;

$color-yellow-1: #fecc00;
$color-yellow-2: #fecc11;
$color-yellow-3: #ddb100;

$color-blue-1: #060b71;

$color-gray-1: #f0f0f0;
$color-gray-2: #9a9a9a;
$color-gray-3: #ccc;
$color-gray-4: #b1b1b1;
$color-gray-5: #5b5b5b;
$color-gray-6: #7a7a7a;
$color-gray-7: #939393;
$color-gray-8: #7c7c7c;
$color-gray-9: #8c8c8c;
$color-gray-10: #ebebeb;
$color-gray-11: #565656;
$color-gray-12: #c9c9c9;
$color-gray-13: #e9e9e9;
$color-gray-14: #797979;

$color-green-1: #95c11f;
$color-green-2: #39b54a;

$color-liliac-1: #ab8cc0;

$color-light-a1: rgba(255,255,255,.1);
$color-light-a2: rgba(255,255,255,.2);
$color-light-a3: rgba(255,255,255,.3);
$color-light-a4: rgba(255,255,255,.4);
$color-light-a5: rgba(255,255,255,.5);
$color-light-a6: rgba(255,255,255,.6);
$color-light-a7: rgba(255,255,255,.7);
$color-light-a8: rgba(255,255,255,.8);
$color-light-a9: rgba(255,255,255,.9);

$color-dark-a1: rgba(0,0,0,0.1);
$color-dark-a2: rgba(0,0,0,0.2);
$color-dark-a3: rgba(0,0,0,0.3);
$color-dark-a4: rgba(0,0,0,0.4);
$color-dark-a5: rgba(0,0,0,0.5);
$color-dark-a6: rgba(0,0,0,0.6);
$color-dark-a7: rgba(0,0,0,0.7);
$color-dark-a8: rgba(0,0,0,0.8);
$color-dark-a9: rgba(0,0,0,0.9);

$color-brand: $color-yellow-1;

//Font
$font-r: 'Roboto', 'Helvetica', sans-serif;

$font-family-base: $font-r;
$font-color-base: black;
$font-size: 16px;
