$s: 1.1;
$i: 11;
@while $i < 40 {
    .u-size-#{$i} {
        font-size: #{$s}em;
    }
    $s: $s + 0.1;
    $i: $i + 1;
}

.u-size-upper {
    text-transform: uppercase;
}

.u-size-lower {
    text-transform: lowercase;
}