.gallery {
    &__item {
    }
    
    &__wrap {
        background: $color-gray-10;
        height: 100%;
    }

    &__img {
        line-height: 0;
        filter: brightness(100%);
        transition: filter 0.3s;
        overflow: hidden;

        &--animate {
            &:hover {
                filter: brightness(60%);
                transition: filter 0.3s;
    
                .gallery__image {
                    transform: scale(1.5);
                    transition: transform 1s;
                }
            }
        
            .gallery__image {
                transform: scale(1);
                transition: transform 1s;
            }
        }
    }

    &__counter {
        .icon {
            fill: white;
            margin-right: 10px;
            position: relative;
            top: 1px;
        }
    }

    &__counter-text {
        font-size: 0.875em;
        line-height: 1em;
        vertical-align: middle;
    }

    &__link {
        display: block;
    }

    &__content {
        padding: 16px 22px;
        font-weight: 500;
        font-size: 0.875em;
    }
}