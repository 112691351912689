 .submenu {
    .grid {
        padding: 0;

        @include susy-breakpoint($sm) {
            padding: 45px 0;
        }

        @include susy-breakpoint($md) {
            padding: 45px 15px;
        }
    }

     &__item {
        height: 80px;

        @include susy-breakpoint($md) {
            height: 123px;
        }
     }

     .button-wrap {
         height: 100%;
     }

     &__button {
        
        @include susy-breakpoint($md) {
            border-right: none;
        }

        @include susy-breakpoint($xl) {
            font-size: 1.25em;
        }

        .button {
            font-weight: bold;
        }

        &--1 {
            .icon {
                transform: scale(-1, 1);
                margin-right: 10px;

                @include susy-breakpoint($lg) {
                    width: 65px;
                    height: 65px;
                }
            }
            .button {
                border-bottom: none;

                @include susy-breakpoint($md) {
                    border-right: none;
                    border-bottom: solid 1px $color-gray-3;
                }
            }
        }

        &--2 {
            .icon {
                margin-right: 20px;

                @include susy-breakpoint($lg) {
                    width: 53px;
                    height: 53px;
                }
            }
            .button {
                border-bottom: none;

                @include susy-breakpoint($md) {
                    border-right: none;
                    border-bottom: solid 1px $color-gray-3;
                }
            }
        }

        &--3 {
            .icon {
                margin-right: 10px;

                @include susy-breakpoint($lg) {
                    width: 70px;
                    height: 70px;
                }
            }
        }
     }
 }