.modal {
	display: none;

	&--active {
		display: block;

		.modal__cover {
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: $color-dark-a6;
			z-index: 20;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.modal__wrap {
			background: white;
			height: auto;
			position: relative;
			margin-top: 50px;
			
			@include susy-breakpoint ($xl) {
				margin-top: 0;
			}
		}

		.modal__close {
			position: absolute;
			right: 25px;
			top: 25px;
			cursor: pointer;
			z-index: 30;

			&:hover {
				.icon {
					fill: red;
				}
			}
		}

		.modal__text {
			z-index: 25;
			
			p {
				margin: 0;
				padding: 0;
			}
		}
	}
}