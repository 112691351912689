.card {
    position: relative;

    &__img {
        line-height: 0;
        font-size: 0;
        margin-bottom: 25px;
    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__content {
        padding: 0 14px;

        .heading {
            font-size: 1em;
            line-height: 1.75;
        }

        @include susy-breakpoint($lg) {
             padding: 0 34px;
        }
    }

    &--bordered {
        border: solid 1px $color-gray-3;
    }
}