.turnament {

    &__year-value {
        display: block;
        font-size: 1.125em;
        font-weight: 500;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $color-gray-11;
        padding: 10px 40px;
        background: $color-yellow-1;
        text-align: center;
        
        @include susy-breakpoint($md) {
            display: inline-block;
            text-align: left;
            background: $color-gray-1;
        }
    }

    &__date {
        text-align: center;
        @include susy-breakpoint($md) {
            text-align: left;
            width: 120px;
        }
    }

    &__title {
        text-align: center;
        @include susy-breakpoint($md) {
            text-align: left;
        }
    }

    &__org {
        text-align: center;
        @include susy-breakpoint($md) {
            text-align: left;
        }
    }

    &__docs {
        text-align: center;
        @include susy-breakpoint($md) {
            width: 180px;
        }
    }

    .files__link {
        display: inline-block;
        padding-top: 10px;
        text-decoration: none;
        padding-left: 30px;

        &:first-child {
            padding: 0;
        }

        &.files__empty {
            .icon {
                fill: $color-gray-12;
            }
        }
    }

    .icon {
        &--prize {
            fill: $color-yellow-1;
        }
    }

    .table__row {
        &--body {
            &:last-child {
                margin: 0;
            }
        }
    }
}