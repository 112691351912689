.table {
    display: table;
    width: 100%;
    margin-bottom: 30px;

    &__row {
        &--head {
            display: none;

            @include susy-breakpoint($md) {
                display: table-row;
            }
        }

        &--body {
            background: $color-gray-1;
            padding: 15px;
            margin-bottom: 30px;

            @include susy-breakpoint($md) {
                display: table-row;
                background: none;
                padding: 0;
                margin-bottom: 0;
            }
        }
    }

    &__head {
        @include susy-breakpoint($md) {
            display: table-cell;
            padding: 10px 15px;
            border-top: 1px solid $color-gray-3;
            border-bottom: 1px solid $color-gray-3;
            font-size: 0.875em;
            font-weight: 500;
            background: $color-gray-3;
            border-right: 1px solid $color-gray-3;
            
            &:first-child {
                border-left: 1px solid $color-gray-3
            }
        }
    }

    &__cell {
        display: flex;
        flex-direction: column;
        padding-bottom: 5px;
        font-size: 1em;
        font-weight: normal;
        margin-bottom: 5px;
        border-bottom: 1px solid $color-gray-3;

        &:last-child {
            border-bottom: 0;
        }
        
        @include susy-breakpoint($md) {
            display: table-cell;
            padding: 10px 15px;
            margin: 0;
            border-right: 1px solid $color-gray-3;
            
            &:first-child {
                border-left: 1px solid $color-gray-3;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray-3;
            }
        }
    }

    &__cell-head {
        flex: 0 0 50%;
        max-width: 50%;

        @include susy-breakpoint(400px) {
            flex: 0 0 30%;
            max-width: 30%;
        }

        @include susy-breakpoint($md) {
            display: none;
        }
    }

    &__cell-value {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 15px;

        @include susy-breakpoint(400px) {
            flex: 0 0 70%;
            max-width: 70%;
        }

        @include susy-breakpoint($md) {
            max-width: none;
            padding-left: 0;
        }
    }

    &--xs {
        // display: table;
        // width: 100%;
        // margin-bottom: 25px;
    
        &__row {
            // display: table-row;
            
            // &:hover {
            //     background: $color-gray-6;
            // }
        }
    
        &__head {
            // font-weight: bold;
            // background: $color-blue-1;
            // color: white;
            // border-left: 1px solid white;
        }
    
        &__cell {
    
        }
    
        &__cell,
        &__head {
            // display: table-cell;
            // padding: 10px 15px;
            // border-bottom: 1px solid $color-blue-1;
        }
    }
}