.button-wrap {
    
}
.button {
    display: inline-block;
    border-radius: 2px;
    background-color: $color-brand;
    border: solid 2px $color-brand;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.875em;
    letter-spacing: 0.7px;
    text-align: center;
    line-height: 1.75em;
    padding: 0 25px;
    font-weight: 500;

    &:hover {
        background: none;

        &.button--color-inverse {
            color: white;
        }
    }

    &--dark {
        background-color: $color-dark-2;
        border-color: $color-dark-2;
        border-radius: 3px;
        color: white;

        &:hover {
            background-color: $color-dark-3;
            border-color: $color-dark-3;
        }
    }

    &--light {
        background-color: white;
        border: solid 1px $color-gray-3;

        &:hover {
            background-color: $color-brand;
            border-color: $color-brand;
        }
    }

    &--bordered {
        background: none;  

        &:hover {
            background: $color-brand;
        }
    }

    &--big {
        font-size: 1.25;
        line-height: 2.19em;
        font-weight: bold;
        padding-top: 54px; 
        padding-bottom: 54px; 
    }

    &--icon {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &--full {
        width: 100%;
        height: 100%;
    }

    &--center {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);

        strong {

        }
    }
}