.timing-subtitle p {
    font-size: 16px;
    font-weight: 500;
    color: $color-dark-1;
    
    @include susy-breakpoint($md) {
        font-size: 20px;        
    }
}

.timing {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;

    &__item {
        flex: 0 0 100%;
        max-width: 100%;
        
        @include susy-breakpoint($xs) {
            flex: 0 0 span(1 of 2);
            max-width: span(1 of 2);
        }
        
        @include susy-breakpoint($md) {
            flex: 0 0 span(1 of 3);
            max-width: span(1 of 3);
        }
        
        @include susy-breakpoint($lg) {
            flex: 0 0 span(1 of 4);
            max-width: span(1 of 4);
        }
    }

    &__item-wrap {
        padding-left: 15px;
        padding-right: 15px;
    }

    &__title {
        padding: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid $color-gray-3;
        background: $color-yellow-1;
        color: $color-dark-1;
    }

    &__content {
        padding: 20px 10px;
        border-bottom: 1px solid $color-gray-3;
        border-left: 1px solid $color-gray-3;
        border-right: 1px solid $color-gray-3;
        min-height: 120px;

        &--empty {
            vertical-align: middle;
            line-height: 60px;
        }
    }

    &__class {
        font-size: 16px;
        font-weight: 500;
    }

    &__time {
        font-size: 20px;
        font-weight: 500;

        .icon {
            fill: $color-yellow-1;
        }
    }

    &__guru {
        font-size: 14px;
        font-weight: normal;
        color: $color-gray-14;
    }
}
