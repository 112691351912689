#news {
    .heading {
        padding-top: 0;
    }
}

.news {
    .heading {
        letter-spacing: 0;

        @include susy-breakpoint($sm) {
            padding-top: 10px;
        }

        .button {
            display: none;
            
            @include susy-breakpoint($sm) {
                display: inline-block;
                font-size: 14px;
                padding-top: 6px;
                padding-bottom: 4px;
                margin-top: -8px;
            }
        }
    }

    .card {
        @include clearfix;
        height: 100%;
        
        &__img-block {
            @include susy-breakpoint($xs) {
                @include span(1 of 3 no-gutter);
            }

            @include susy-breakpoint($md) {
                @include span(full no-gutter);
            }

            .date {
                padding: 0 14px 10px;

                @include susy-breakpoint($md) {
                    padding: 0 14px 20px;
                }

                @include susy-breakpoint($lg) {
                    padding: 0 34px;
                }
            }
        }

        &__img {
            @include susy-breakpoint($xs) {
                margin-bottom: 10px;
            }

            @include susy-breakpoint($md) {
                margin-bottom: 20px;
            
                .card__image {
                    filter: brightness(100%);
                    transition: filter 0.3s;

                    &:hover {
                        filter: brightness(60%);
                        transition: filter 0.3s;
                    }
                }
            }
        }

        &__content {
            padding-bottom: 25px;
            line-height: 1.5;
            letter-spacing: normal;            
            
            @include susy-breakpoint($xs) {
                @include span(2 of 3 no-gutter last);
                padding-top: 10px;
                padding-left: 20px;
                height: 100%;
            }

            @include susy-breakpoint($md) {
                @include span(full no-gutter);
                padding-top: 0;
                padding-left: 14px;
                height: auto;
                padding-bottom: 35px;
            }

            @include susy-breakpoint($lg) {
                padding-left: 34px;
            }
        }

        .eye {
            position: absolute;
            bottom: 10px;
            right: 14px;
            padding-bottom: 0;
        }
    }

    &__button-block {
        display: block;
        
        @include susy-breakpoint($sm) {
            display: none;
        }

        .button {
            padding: 10px 45px;
        }
    }

    .date {
        text-align: center;
        padding-bottom: 10px;
        
        @include susy-breakpoint($sm) {
            text-align: left;
        }
    }

    .author {
        text-align: center;
        padding-bottom: 20px;
        font-size: 0.75em;;
        font-weight: normal;
        font-style: italic;
        color: $color-dark-2;
        
        @include susy-breakpoint($sm) {
            text-align: right;
        }
    }

    &__go-to-papa {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        color: $color-dark-1;
        padding-bottom: 30px;

        .icon {
            transform: rotate(180deg);
            margin-right: 9px;
        }
    }
    
    .soc-buttons {
        font-weight: bold;
        font-style: normal;
        text-transform: uppercase;
        line-height: 1.63;
        text-align: center;

        @include susy-breakpoint($sm) {
            text-align: left;
        }
    }

    &__gallery-btn {
        .button-wrap {
            text-align: center;

            @include susy-breakpoint($sm) {
                text-align: left;
            }
        }

        .button {
            font-size: 0.875em;
            font-weight: 500;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 0.7px;
            padding: 12px;

            &:hover {
                background-color: $color-yellow-3;
                border-color: $color-yellow-3;

                .icon {
                    fill: white;
                }
            }

            .icon {
                margin-left: 5px;
            }
        }
    }

    .left-content {
        & > .heading {
            font-size: 0.9em;
            text-transform: uppercase;
            font-weight: 500;
            font-style: normal;
            line-height: 1.33;
            padding: 0;
            text-align: center;
            padding: 15px;

            @include susy-breakpoint($sm) {
                font-size: 1.125em;
            }
        }
    }
}