.friends {
    position: relative;
    
    &__item {
        padding-left: 10px;
        padding-right: 10px;
    }

    &__wrap {
        
    }

    &__img {
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__image {
        max-width: 100%;
    }

    &__name {
        text-align: center;
        line-height: 1.5;
    }

    &__link {
        font-size: 0.937em;
        font-weight: bold;
        color: black;
    }

    .slick-arrow {
        width: 57px;
        height: 57px;
        top: 37%;
        background-color: white;
        transition: background 0.3s;
        z-index: 10;

        &::before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border: 3px solid $color-gray-5;
            transform: rotate(45deg);
            position: absolute;
            top: 38%;
            opacity: 1;
        }

        &:hover {
            background: $color-gray-1;
            transition: background 0.3s;
        }

        &.slick-next {
            right:0;

            @include susy-breakpoint (460px){
                right: -16px;
            }

            @include susy-breakpoint ($md){
                right: -62px;
            }

            @include susy-breakpoint ($lg){
                right: -80px;
            }

            @include susy-breakpoint ($xl){
                right: -92px;
            }

            &::before {
                left: 34%;
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }

        &.slick-prev {
            left: 0;

            @include susy-breakpoint (460px){
                left: -16px;
            }

            @include susy-breakpoint ($md){
                left: -62px;
            }

            @include susy-breakpoint ($lg){
                left: -80px;
            }

            @include susy-breakpoint ($xl){
                left: -92px;
            }

            &::before {
                left: 47%;
                border-top-color: transparent;
                border-right-color: transparent;
            }
        }
    }
}